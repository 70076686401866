"use strict";

/**
 * Define the output of this file. The output of CSS and JS file will be auto detected.
 *
 * @output plugins/global/plugins.bundle
 */



//** Begin: Global mandatory plugins
window.jQuery = window.$ = require("jquery");
require("bootstrap");
require("morris.js/morris.js");
require("block-ui");
require("autosize");
require("clipboard");
window.moment = require("moment");
window.Sticky = require("sticky-js");
window.Chart = require("chart.js");
window.Raphael = require("raphael");
window.Cookies = require("js-cookie");
window.Popper = require("popper.js");
require("jquery-form");

// Toastr
require("toastr/build/toastr.css");
window.toastr = require("toastr");

// Tooltips
import Tooltip from "tooltip.js";
window.Tooltip = Tooltip;

// Perfect-Scrollbar
require("perfect-scrollbar/css/perfect-scrollbar.css");
window.PerfectScrollbar = require("perfect-scrollbar/dist/perfect-scrollbar");
//** End: Globally mandatory plugins

// Daterangepicker
require("bootstrap-daterangepicker/daterangepicker.css");
require("bootstrap-daterangepicker");

// Bootstrap-Datepicker
require("bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css");
require("bootstrap-datepicker");
require("metronic/_theme/js/global/integration/plugins/bootstrap-datepicker.init");

// Bootstrap-Datetimepicker
require("bootstrap-datetime-picker/css/bootstrap-datetimepicker.css");
require("bootstrap-datetime-picker");

// Select2
require("select2/dist/css/select2.css");
require("select2");

// Bootstrap-Timepicker
require("bootstrap-timepicker/css/bootstrap-timepicker.css");
require("bootstrap-timepicker");


// Inputmask
window.Inputmask = require("inputmask");

// jQuery-Validation
require("jquery-validation");
require("jquery-validation/dist/additional-methods.js");
require("metronic/_theme/js/global/integration/plugins/jquery-validation.init");

// Animate.css
require("animate.css/animate.css");

// Font Icons
require("metronic/_theme/plugins/line-awesome/css/line-awesome.css");
require("metronic/_theme/plugins/flaticon/flaticon.css");
require("metronic/_theme/plugins/flaticon2/flaticon.css");
require("@fortawesome/fontawesome-free/css/all.min.css");
// require("socicon");
//** End: Global optional plugins

// Datatables.net
require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-responsive-bs4");
require("metronic/_theme/js/global/integration/plugins/datatables.init.js");

require("datatables.net-bs4/css/dataTables.bootstrap4.css");
require("datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css");

window.Handlebars = require("handlebars")
require("handlebars_helpers/compare")

require("jquery-editable-select")
require("jquery-editable-select/dist/jquery-editable-select.css")

require('chartjs-plugin-datalabels');
require('jquery.flot');
